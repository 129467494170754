@media (max-width: 1200px) {
    html {
        margin: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .home-img img {
        width: 13vw;
        margin-left: 10rem;

    }

    .about-img img {
        width: 20vw;

    }

}


@media (max-width: 991px) {
    .header {
        padding: 2rem 3%;
    }

    section {
        padding: 10rem 3% 2rem;
    }

    .portfolio {
        padding-bottom: 7rem;
    }

    .contact {
        min-height: auto;
    }

    footer {
        padding: 2rem 3%;
    }
}


@media (max-width: 768px) {
    #menu-icon {
        display: block;
    }

    .navbar {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 1rem 3%;
        background: var(--bg-color);
        border-top: .1rem solid rgba(0, 0, 0, .2);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
        display: none;
    }

    .navbar.active {
        display: block;
    }

    .navbar a {
        display: block;
        font-size: 2rem;
        margin: 3rem 0;
    }

    .home {
        flex-direction: column;
    }

    .home-content h3 {
        font-size: 2.6rem;
    }

    .home-content h1 {
        font-size: 5rem;
    }

    .home-img img {
        display: none;
        margin-top: 3rem;
    }

    .about {
        flex-direction: column-reverse;
    }

    .about img {
        width: 30vw;
        display: flex;
        justify-content: center;
    }

    .portfolio h2 {
        margin-bottom: 3rem;
    }

    .portfolio-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 617px) {
    .portfolio-container {
        grid-template-columns: 1fr;
    }

    .home-img img {
        display: none;
    }

    .about-img img {
        width: 40vw;
    }
}

@media (max-width: 450px) {
    html {
        font-size: 50%;
    }

    .contact form .input-box input {
        width: 100%;

    }
}

@media (max-width: 365px) {
    .home-img img {
        display: none;
    }

    .about-img img {
        width: 30rem;
    }

    .about-content h1,
    .heading {
        font-size: 20px;
        display: flex;
        justify-content: center;
    }

    .footer {
        flex-direction: column-reverse;
    }

    .footer p {
        text-align: center;
        margin-top: 2rem;
    }

    .contact-social-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contact-social-media a,
    #contact-mail {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 2rem;
        width: 2rem;
        height: 2rem;
        border: none;
        font-size: 2rem;

    }

}