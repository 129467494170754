.contact h2 {
    margin-bottom: 3rem;
    font-size: 3rem;
}

.contact form {
    max-width: 70rem;
    margin: 1rem auto;
    text-align: center;
    margin-bottom: 3rem;
}

.contact form .input-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact form .input-box input,
.contact form textarea {
    width: 100%;
    padding: 1.5rem;
    font-size: 1.6rem;
    color: var(--text-color);
    background: var(--second-bg-color);
    border-radius: .8rem;
    margin: .7rem;
}

.contact form .input-box input {
    width: 48%;
}

.contact form textarea {
    resize: none;
}

.contact form .btn {
    margin-top: 2rem;
    cursor: pointer;
}

.contact-social-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-social-media a,
#contact-mail {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: none;
    border: .2rem solid var(--main-color);
    border-radius: 50%;
    font-size: 2rem;
    color: var(--main-color);
    margin: 0.5rem 2.5rem 0.5rem;
    transition: .5s ease;
}

#contact-mail:hover {
    cursor: pointer;
    background: var(--main-color);
    color: var(--second-bg-color);
    box-shadow: 0 0 1rem var(--main-color);
}

.contact-social-media a:hover {
    background: var(--main-color);
    color: var(--second-bg-color);
    box-shadow: 0 0 1rem var(--main-color);
}


.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 9%;
}

.footer-text p {
    font-size: 1.6rem;
}