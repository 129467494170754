@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c8c8c8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c2fd07;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

:root {
  --bg-color: rgb(2, 4, 2);
  --second-bg-color: #0e100e;
  --text-color: #fff;
  --main-color: #c2fd07;
}

section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}


html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  background: var(--bg-color);
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}