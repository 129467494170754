.about {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background: var(--second-bg-color);
}

.about-img img {
    width: 15vw;
    position: relative;
    left: 30%;
    animation: breathe 6s ease-in-out infinite;
}

@keyframes breathe {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.heading {
    text-align: center;
    font-size: 4.5rem;
}

.about-content h2 {
    text-align: left;
    line-height: 1.2;
    font-size: 3rem;
    margin: 0;
    padding: 0rem 0rem 2rem;
}

.about-content p {
    text-align: left;
    font-size: 2.2rem;
    margin: 1rem 0 3rem;
}

.about-content {
    max-width: 80rem;
    padding: 2rem;
    background-color: var(--main-bg-color);
    color: var(--text-color);
}

@media (max-width: 768px) {
    .about .about-img {
        flex: 1 0 100%;
        order: 1;
        animation: breathe 3s ease-in-out infinite;
    }

    .about .about-content {
        flex: 1 0 100%;
        order: 2;
    }
}