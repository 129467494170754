.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 9%;
    background: var(--bg-color);
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

.header {
    border-bottom: .1rem solid rgba(0, 0, 0, 0);
}

.logo {
    font-size: 2.2rem;
    color: var(--text-color);
    font-weight: 600;
    cursor: default;
}

#menu-icon {
    font-size: 3.6rem;
    color: var(--text-color);
    display: none;
}

.navbar a {
    font-size: 2.2rem;
    color: var(--text-color);
    font-weight: 600;
    margin-left: 4rem;
    transition: .3s;
}

.navbar a:hover,
.navbar a.active {
    color: var(--main-color);
    transition: .3s;
}