.portfolio {
    background: var(--second-bg-color);
}

.portfolio h2 {
    margin-bottom: 4rem;
}

.portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 2.5rem;
}

.portfolio-container .portfolio-box {
    position: relative;
    border-radius: 2rem;
    box-shadow: 0 0 1rem rgb(72, 72, 72);
    overflow: hidden;
    display: flex;
}

.portfolio-box img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    transition: all 0.5s ease;
    backface-visibility: hidden;
    transform: translateZ(0);
}

.portfolio-box:hover img {
    transform: scale(1.1);
}

.portfolio-box .portfolio-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(43, 43, 43, 0.614), rgba(0, 0, 0, 0.941));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 4rem;
    transform: translateY(100%);
    transition: .5s ease;
}

.portfolio-box:hover .portfolio-layer {
    transform: translateY(0);
}

.portfolio-layer h4 {
    font-size: 3rem;
}

.portfolio-layer p {
    font-size: 1.5rem;
    margin: .3rem 0 1rem;
}

.portfolio-layer a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: var(--text-color);
    border-radius: 50%;
}

.portfolio-layer a i {
    font-size: 2rem;
    color: var(--second-bg-color);
}