.home-img img {
    width: 20rem;
    margin-left: 10rem;
    animation: floatImage 10s ease-in-out infinite;
}

@keyframes floatImage {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-2.4rem);
    }

    100% {
        transform: translateY(0);
    }
}

.home {
    display: flex;
    justify-content: center;
    align-items: center;
}


.home-content h3 {
    font-size: 3.2rem;
    font-weight: 900;
}

.home-content h3:nth-of-type(2) {
    margin-bottom: 2rem;
}

span {
    color: var(--main-color)
}

.home-content h1 {
    font-size: 5.6rem;
    font-weight: 1000;
    line-height: 1.3;
}

.home-content p {
    font-size: 1.8rem;
}

.social-media a,
#mail {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: none;
    border: .2rem solid var(--main-color);
    border-radius: 50%;
    font-size: 2rem;
    color: var(--main-color);
    margin: 3rem 1.5rem 3rem 0;
    transition: .5s ease;
}

#mail:hover {
    cursor: pointer;
    background: var(--main-color);
    color: var(--second-bg-color);
    box-shadow: 0 0 1rem var(--main-color);
}

.social-media a:hover {
    background: var(--main-color);
    color: var(--second-bg-color);
    box-shadow: 0 0 1rem var(--main-color);
}

.btn {
    display: inline-block;
    padding: 1rem 2.8rem;
    background-color: var(--main-color);
    border-radius: 1rem;
    box-shadow: 0 0 1rem var(--main-color);
    font-size: 1.5rem;
    color: var(--second-bg-color);
    letter-spacing: .1rem;
    font-weight: 600;
    transition: .5s ease;
}

.btn2 {
    display: inline-block;
    padding: 0.5rem 2rem;
    background-color: rgb(225, 225, 225);
    border-radius: 1rem;
    margin-left: 3rem;
    box-shadow: 0 0 0.7rem rgb(225, 225, 225);
    font-size: 1.2rem;
    color: var(--second-bg-color);
    letter-spacing: .1rem;
    font-weight: 800;
    transition: .5s ease;
}

.btn:hover {
    color: white;
    background-color: var(--main-color-darker);
    box-shadow: 0 0 1rem var(--main-color);
}

.btn2:hover {
    color: white;
    background-color: rgba(255, 0, 0, 0);
    box-shadow: 0 0 1rem rgb(225, 225, 225);
}